import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { FetchDataService } from "src/app/core/services/fetch-data.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    verifyLink;
    verifyId;
    countryCodesList = [];
    registerType;
    @ViewChild("formDir") private formDir: NgForm;
    responseMessage;
    errorMessage;
    email;
    id;
    type;
    hide : boolean = true;
    hides : boolean = true;
    maxD:any;
    hideRest = true;

    constructor(private fb: FormBuilder, private fd: FetchDataService, private ad: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.verifyLink = this.router.url.split('=');
            this.verifyEmail(this.verifyLink[1]);

        this.getCountryCodes();
        this.ad.queryParamMap.subscribe((params: Params) => {
            this.email = params.params.email;
            this.id = params.params.id;
            this.type = params.params.type;
        });
        
        this.registerForm = this.fb.group(
            {
                id: this.id,
                email: [this.email, [Validators.required, Validators.email]],
                password: ["", [Validators.required, Validators.minLength(4)]],
                rpassword: ["", Validators.required],
                first_name: ["", [Validators.required]],
                last_name: ["", [Validators.required, Validators.minLength(3)]],
                country: ["", Validators.required],
                country_code: ["", Validators.required],
                phone: ["", [Validators.required,Validators.minLength(10)]],
                register_as: ["", Validators.required],
                // type: [this.type, Validators.required]
            },
            { validator: this.passwordConfirming }
        );
        // this.ad.queryParamMap.subscribe((params: Params) => {
        //     this.verifyLink = this.router.url.split('=');
            
        //     this.verifyEmail(this.verifyLink[1]);
        // });

        // var radio = document.querySelector('.radio');
        //    var inputs = document.querySelector('.inputs');

        //     radio.addEventListener('click', function(){
        //             inputs.classList.toggle('hidden')
        //                  })


    }

    showPassword() {
        this.hide = !this.hide;
      }
      showPassword2() {
        this.hides = !this.hides;
      }

    allowOnlyAlphabets(event: KeyboardEvent): void {
        const charCode = event.key.charCodeAt(0);
        // Allow alphabets (A-Z, a-z)
        if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
          return;
        } else {
          event.preventDefault();
        }
      }

      alphanumericValidator(control: any) {
    const alphanumericPattern = /^[a-zA-Z0-9]*$/;
    if (control.value && !alphanumericPattern.test(control.value)) {
      return { nonAlphanumeric: true };
    }
    return null;
  }


    verifyEmail(a:any) {
        // alert(a)
        const formData = new FormData();
        formData.append("h", a);
        this.fd.verifyEmail(formData).subscribe((res) => {
            console.log(res);
            // this.hideRest = true;
            if (res.code == 1) {
                this.hideRest = false;
                this.registerType = res.data.type;
                if (res.code == 1) {
                    this.verifyId = res.data.id;
                    console.log(this.verifyId);
                    this.registerForm.patchValue({
                        id: res.data.id,
                        email: res.data.email,
                        type: res.data.type
                    });
                }
            }   else if(res.code == 0){
                setTimeout(() => {
                this.router.navigate(["/error/0"]);
                    
                }, 1000);
            }else if(res.code == 2){
                setTimeout(() => {
                    this.router.navigate(["/error/2"]);
                        
                    }, 1000);
            }
        });
    }
    getCountryCodes() {
        this.fd
            .getCountryCodes()
            .pipe(
                map((data: any) => {
                    this.countryCodesList = data.result;
                    console.log(data);
                })
            )
            .subscribe();
    }
    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get("password").value !== c.get("rpassword").value) {
            return { invalid: true };
        }
    }
    isNumber(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    onSelectCountry(item) {
        console.log(item);
        if (item == 'India') {
            this.maxD = '10'
            // this.registerForm.get('phone').setValidators([Validators.required, Validators.maxLength(10)]);
            // this.registerForm.get('phone').updateValueAndValidity();
        } else {
            this.maxD = '15'
            // this.registerForm.get('phone').setValidators([Validators.required, Validators.maxLength(15)]);
            // this.registerForm.get('phone').updateValueAndValidity();

        }
        let indx = this.countryCodesList.findIndex((m) => m.name === item);
        this.registerForm.patchValue({
            country_code: this.countryCodesList[indx]["phonecode"]
        });
    }
    onSubmitRegisterForm() {
        console.log(this.registerForm.value);
        window.scrollTo({ top: 0, behavior: "smooth" });
        // let responseMsg = document.getElementById("responseMsg");
        console.log(this.registerForm)
        if (this.registerForm.valid) {
            const formData = new FormData();
            formData.append("id", this.registerForm.get("id").value);
            formData.append("email", this.registerForm.get("email").value);
            formData.append("password", this.registerForm.get("password").value);
            formData.append("rpassword", this.registerForm.get("rpassword").value);
            formData.append("first_name", this.registerForm.get("first_name").value);
            formData.append("last_name", this.registerForm.get("last_name").value);
            formData.append("country", this.registerForm.get("country").value);
            formData.append("country_code", this.registerForm.get("country_code").value);
            formData.append("phone", this.registerForm.get("phone").value);
            formData.append("register_as", this.registerForm.get("register_as").value);
            // formData.append("type", this.registerForm.get("type").value);
            this.fd.signup(formData).subscribe((res) => {
                if (res.code == 1) {

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your registration request has been received successfully it takes less than 24 hours to review. You would be receiving an email with an update.',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    // this.responseMessage = res.message;
                    setTimeout(() => {
                        this.router.navigate(["account/login"]);
                    }, 3500);
                } else {
                    this.errorMessage = "Please try Again";
                }
            });
        }
    }
}
