<body data-spy="scroll" data-target=".fixed-top">
  <div >
      <!-- Navigation -->
      <nav class="navbar navbar-expand-lg fixed-top navbar-light">
        <div class="container">

            <!-- Text Logo - Use this if you don't have a graphic logo -->
            <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Kora</a> -->

            <!-- Image Logo -->
            <a class="navbar-brand logo-image" href="">
                <img src="assets/images/connekkt-web-logo-black.png" alt="alternative"></a>

            

          
        </div> <!-- end of container -->
    </nav> <!-- end of navbar -->
    <!-- end of navigation -->
  </div>


    <div class="container mt-3">
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex align-items-end justify-content-end ">
              <h4 class="mb-0 font-size-18">Sales Support</h4>
              <div class="page-title-right">
                <ol class="breadcrumb m-0">
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            
              <div class="card-2">
                <!-- <div class="row">
              <div class="col-lg-6 ">
               <img  class="img-fluid" src="assets/images/sales-Support.png"> 
              </div>
              <div class="media-body col-lg-6">
                <h5 class="font-size-15">Quick Way to Connect With your Customers</h5>
                <p class="text-muted">New common language will be more simple and regular than the existing
                  European
                  languages. It will be as simple as occidental.</p>
              </div>
            </div> -->
                <div class="row m-3">
      
                  <div class=" col-lg-6">
                    <h2 class="font-size-35 buyer">Buyer</h2>
                    <p class=" text-muted ">As a Buyer we understand the painful ways
                      one has to go through for getting the desired content in this digital ecosystem
                    <p>
      
                    <p class="text-muted">
                      Connekkt Media has specialized team who can fetch your desired content based on your requirement and
                      handle all the end to end onboarding with ease
                    <p>
      
                    <p class="text-muted">
                      Just opt for the Expert sales support of Connekkt Media and we would reach you for the details and close
                      deals on your behalf</p>
                  </div>
                  <div class="col-lg-6">
                    <img class="img-fluid" src="assets/images/sales-Support-buyer.png">
                  </div>
                </div>
                <div class="row m-3">
                  <div class="col-lg-6">
                    <img class="img-fluid" src="assets/images/sales-Support-seller.png">
                  </div>
                  <div class="col-lg-6 mt-3">
                    <h2 class="font-size-35 seller">Seller</h2>
                    <p class="text-muted">As a seller we understand the painful ways a seller has to go through
                      for marketing the content and getting the right value of the content to reaching the appropriate
                      audience.</p>
                    <p class=" text-muted">Connekkt Media Sales Support solves this issue by identifying right
                      buyers and getting the right value for the deal.</p>
                    <p class=" text-muted">Just register yourself on Connekkt Media and opt for the Expert Sales
                      Support to market your content in a breeze.</p>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                          <button class="btn btn-danger  modalContact" (click)="smallModal(largeDataModal)">Talk to an
                            Expert</button>
                        </div>
                      </div>
                  </div>
                </div>
              
              </div>
           
          </div>
        </div>
      </div>

    <!-- Footer -->
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-col first">
                        <h6>About Connekkt Media</h6>
                        <p class="p-small">Blockchain powered Video/Audio Content Rights Marketplace.
                            World’s only Assisted sales platform for Digital Media Content Rights
                        </p>
                    </div> <!-- end of footer-col -->
                    <div class="footer-col second">
                        <h6>Useful Links</h6>
                        <ul class="list-unstyled li-space-lg p-small">
                            <li><a href="terms.html">Terms & Conditions</a><br/> <a href="privacy.html">Privacy
                                    Policy</a></li>
                            <!-- <li>Useful: <a href="#">Colorpicker</a>, <a href="#">Icon Library</a>, <a
                                    href="#">Illustrations</a></li>
                            <li>Menu: <a class="page-scroll" href="#header">Home</a>, <a class="page-scroll"
                                    href="#intro">Intro</a>, <a class="page-scroll" href="#features">Features</a>, <a
                                    class="page-scroll" href="#details">Details</a></li> -->
                        </ul>
                    </div> <!-- end of footer-col -->
                    <div class="footer-col third">
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-pinterest-p fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
                        <p class="p-small">We would love to hear from you <a
                                href="mailto:help@connekkt.media"><strong>supportconnekkt.media</strong></a>
                                <br/><a
                                    href="tel:+4407466 31893"><strong>+44 07466 331893</strong></a></p>

                    </div> <!-- end of footer-col -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->

             <!-- Copyright -->
    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="p-small">Copyright © <a href="#your-link">Connekkt Media</a></p>
                </div> <!-- end of col -->
            </div> <!-- enf of row -->
        </div> <!-- end of container -->
    </div> <!-- end of copyright -->
    
        </div> <!-- end of container -->
    </div> <!-- end of footer -->
    <!-- end of footer -->

   
</body>


  <ng-template #largeDataModal let-modal>
    <div class="modal-header  no ">
  
      <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
      <h2 class="d-flex justify-content-center mt-0 buyer">Get in touch!</h2>
      <h5 class="d-flex justify-content-center mt-0">Contact us to elevate your Content Outreach</h5>
      <div class="card_2">
        <div class="card_2">
          <div class="col-12">
            <!-- <ngb-alert type="success" *ngIf="responseMessage" [dismissible]="false">
                {{responseMessage | titlecase}}
            </ngb-alert> -->
            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">
              {{error | titlecase}}
            </ngb-alert>
          </div>
  
          <form class="row" [formGroup]="contactForm" #formDir="ngForm" (ngSubmit)="onSubmit()">
            <div class="col-6">
              <div class="mb-3 row">
                <label for="example-text-input" class="col-lg-6 col-form-label"> User Name</label>
                <div class="col-md-12">
                  <input class="form-control" formControlName="name" type="text" value="Name" id="example-text-input"
                    required placeholder="User Name"  autocomplete="off">
                  <div class="invalidError" *ngIf="(contactForm.get('name').invalid && formDir.submitted)">Name is
                    required*</div>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="example-text-input" class="col-lg-6 col-form-label">Email</label>
                <div class="col-md-12">
                  <input class="form-control" formControlName="email" type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value="Email" id="example-text-input" required
                    placeholder="email"  autocomplete="off">
                  <div class="invalidError" *ngIf="(contactForm.get('email').invalid && formDir.submitted)">Email is
                    required*</div>
                </div>
              </div>
              <div class="mb-3 row">
               
                  <label class="col-lg-6 col-form-label"> Country</label>
                 <div class="col-md-12">
                  <select class="form-select" formControlName="country" (change)="onSelectCountry(contactForm.get('country').value)">
                    <option value="" selected disabled>Select Your Country</option>
                    <option *ngFor="let option of countryCodesList" [ngValue]="option.name">{{option.name | titlecase}}</option>
                </select>
                <div class="invalidError" *ngIf="(contactForm.get('country').invalid && formDir.submitted)">Country is required*</div>
                 </div>
             
              </div>
              <div class="mb-3 row">
                <label for="example-text-input" class="col-lg-6 col-form-label">Mobile no.</label>
           <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                  <input type="text" class="form-control" disabled formControlName="country_code">
              </div>
              <div class="col-md-9">
                  <!-- <input type="text" formControlName="phone" pattern="[1-9]{1}[0-9]{9}" (keypress)="isNumber($event)" required class="form-control" placeholder="Enter Phone Number"> -->
                  <input type="text" formControlName="number" maxlength="10" pattern="[1-9]{1}[0-9]{9}" (keypress)="isNumber($event)" required class="form-control" placeholder="Enter Phone Number">
                  <div class="invalidError" *ngIf="(contactForm.get('number').invalid && formDir.submitted)">Mobile Number  is required*</div>
              </div>
          </div>
           </div>
                
              </div>
              <div class="mb-3 row">
                <label for="example-text-input" class="col-lg-6 col-form-label">Services</label>
                <div class="col-md-12">
                  <select class="form-select" formControlName="services" >
                    <option value="" selected disabled>Select your services</option>
                    <option value="buyer">Buyer
                    </option>
                    <option value="seller">Seller
                    </option>
                    <option value="enquiry">Enquiry
                    </option>
  
  
  
  
                  </select>
                  <div class="invalidError" *ngIf="(contactForm.get('services').invalid && formDir.submitted)">Service
                    is required*</div>
                </div>
              </div>
              <div class="row mb-3">
                <re-captcha formControlName="recaptchaReactive" (resolved)="addTokenLog('Reactive form mode resolved', $event)">
                </re-captcha>
                <div class="invalidError" *ngIf="(contactForm.get('recaptchaReactive').invalid && formDir.submitted)">Captcha
                  is required*</div>
    
              </div>
              
            </div>
            <div class="col-6">
              <div class="mb-3 row">
                <label for="example-text-input" class="col-lg-6 col-form-label">Message</label>
                <div class="col-md-12">
                  <textarea class="form-control" type="text" rows="10" formControlName="message"
                    id="example-text-input"></textarea>
                  <div class="invalidError" *ngIf="(contactForm.get('message').invalid && formDir.submitted)">Message
                    is required*</div>
                </div>
              </div>
            </div>
            
          
	
           
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <button class="btn btn-danger contact" type="submit"  >Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      
    </div>
  </ng-template>