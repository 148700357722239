<div>
    <div class="header">
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="byr mt-5 text-center">
                    <h4> Registration </h4>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid p-3">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="tab-content" id="myTabContent">




                    <div class="tab-pane fade show active" id="contact1" role="tabpanel" aria-labelledby="contact-tab1">
                        <div class="ul-tab-content">
                            <div class="tab-content card p-5" id="myTabContentJust">
                                <div class="row">
                                    <div class="col-12">
                                        <ngb-alert type="success" *ngIf="responseMessage" [dismissible]="false"> {{responseMessage | titlecase}}
                                        </ngb-alert>
                                        <ngb-alert type="warning" *ngIf="errorMessage" [dismissible]="false"> {{errorMessage | titlecase}}
                                        </ngb-alert>
                                    </div>
                                    <div class="col-md-12">
                                        <form [formGroup]="registerForm" #formDir="ngForm" (ngSubmit)="onSubmitRegisterForm()">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="control-label"> Email ID*</label>
                                                    <input maxlength="100" disabled type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" formControlName="email" required="required" class="form-control" placeholder="Enter Email" />
                                                    <div class="invalidError" *ngIf="(registerForm.get('email').invalid && formDir.submitted)">Email is required*</div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" formControlName="register_as" value="1" name="register_as">Individual
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline radio">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" formControlName="register_as" value="2" name="register_as">Company
                                                        </label>
                                                    </div>
                                                    <!-- <div class="inputs hidden">
                                                        <input type="text">
                                                        <input type="text">
                                                    </div> -->
                                                    <div class="invalidError" *ngIf="(registerForm.get('register_as').invalid && formDir.submitted)">Register as is required*</div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="control-label">Enter Password*</label>
                                                    <input maxlength="10" type="password" formControlName="password" required="required" class="form-control" placeholder="Enter Password" />
                                                    <div class="invalidError" *ngIf="(registerForm.get('password').invalid && formDir.submitted)">Password is required & it should be alphanumeric*</div>
                                                    
                                                    
                                                </div>
                                                <div class="form-group">
                                                    <label class="control-label">Re-Enter Password*</label>
                                                    <input maxlength="10" type="password" formControlName="rpassword" required="required" class="form-control" placeholder="Re-Enter Password" />
                                                    <div *ngIf="(registerForm.get('rpassword').invalid && formDir.submitted)" class="invalidError">Retype Password is required & it should be alphanumeric*</div>
                                                    <div *ngIf="registerForm.get('rpassword').dirty" class="invalidError">
                                                        <span *ngIf="registerForm.errors?.invalid">
                                                            Password doesn't match*
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="control-label"> First Name*</label>
                                                    <input (keydown)="allowOnlyAlphabets($event)" maxlength="100" type="text" formControlName="first_name" required="required" class="form-control" placeholder="Enter First Name"  />
                                                    <div class="invalidError" *ngIf="(registerForm.get('first_name').invalid && formDir.submitted)" >Fist Name is required*</div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="control-label"> Last Name*</label>
                                                    <input (keydown)="allowOnlyAlphabets($event)" maxlength="100" type="text" formControlName="last_name" required="required" class="form-control" placeholder="Enter Last Name" />
                                                    <div class="invalidError" *ngIf="(registerForm.get('last_name').invalid && formDir.submitted)">Last Name is required*</div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="control-label"> Country*</label>
                                                    <select class="form-control" formControlName="country" (change)="onSelectCountry(registerForm.get('country').value)">
                                                        <option value="" selected disabled>Select Your Country</option>
                                                        <option *ngFor="let option of countryCodesList" [ngValue]="option.name">{{option.name | titlecase}}</option>
                                                    </select>
                                                    <div class="invalidError" *ngIf="(registerForm.get('country').invalid && formDir.submitted)">Country is required*</div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="control-label"> Phone Number*</label>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <input type="text" class="form-control" disabled formControlName="country_code">
                                                        </div>
                                                        <div class="col-md-9">
                                                            <!-- <input type="text" formControlName="phone" pattern="[1-9]{1}[0-9]{9}" (keypress)="isNumber($event)" required class="form-control" placeholder="Enter Phone Number"> -->
                                                            <input type="text" formControlName="phone" minlength="6" (keypress)="isNumber($event)" required class="form-control" placeholder="Enter Phone Number">
                                                            <div class="invalidError" *ngIf="(registerForm.get('phone').invalid && formDir.submitted)">Phone No is required*</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <div>
                                                        <label for="type" class="form-label">Type*</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" formControlName="type" value="1" name="type">Buyer
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" formControlName="type" value="2" name="type">Seller
                                                        </label>
                                                    </div>
                                                    <!-- <div class="form-check form-check-inline">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio" formControlName="type" value="3" name="type">Service Provider
                                                        </label>
                                                    </div> -->
                                                    <div class="invalidError" *ngIf="(registerForm.get('type').invalid && formDir.submitted)">
                                                        <div>Type is required*</div>
                                                    </div>
                                                </div>

                                                <!-- <div class="form-group">
                                                    <label for="">Type</label>
                                                    <div>
                                                        <button class="btn" [ngClass]="(registerType == 1)? 'btn-success': 'btn-danger'">
                                                            <i class="fa text-white" [ngClass]="(registerType == 1)? 'fa-check-circle': 'fa-times-circle'"></i>
                                                            As Buyer
                                                        </button> &nbsp;
                                                        <button class="btn btn-danger" [ngClass]="(registerType == 2)? 'btn-success': 'btn-danger'">
                                                            <i class="fa text-white" [ngClass]="(registerType == 2)? 'fa-check-circle': 'fa-times-circle'"></i>
                                                            As Seller
                                                        </button> &nbsp;
                                                        <button class="btn btn-danger" [ngClass]="(registerType == 3)? 'btn-success': 'btn-danger'">
                                                            <i class="fa text-white" [ngClass]="(registerType == 3)? 'fa-check-circle': 'fa-times-circle'"></i>
                                                            As Service Provider
                                                        </button>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="">
                                                <div class="mt-5 d-grid">
                                                    <button class="btn btn-primary" type="submit">Register</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div>