<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="../../../assets/images/connekkt-media-logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/connekkt-web-logo-white.png" alt="" height="32">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>


      <!-- <div class="dropdown dropdown-mega d-none d-lg-block me-2" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle>
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">

              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.APPLICATIONS.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.ECOMMERCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CALENDAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.EMAIL' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.PROJECTS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.TASKS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CONTACTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.EXTRA_PAGES.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMING SOON' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.TIMELINE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.FAQ' | translate}}</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="d-flex">



      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown >
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle (click)="moveChatlogs()"> 
          <i class="bx bx-bell bx-tada"></i>
          <span *ngIf="data1 !=0" class="badge bg-danger rounded-pill">{{data1}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
         
         
         
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/users/ic_default.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1 text-capitalize">{{ user.first_name + " " + user.last_name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);" (click)="gotoProfile()"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
            <a class="dropdown-item" href="javascript: void(0);"  (click)="gotoChangePassword()"><i class="bx bx-edit-alt font-size-16 align-middle me-1"></i>
              Change Password</a>
              <a class="dropdown-item" href="javascript: void(0);" (click)="gotoWatchList()" ><i class="bx bx-list-check font-size-16 align-middle me-1"></i>
                Watch List</a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a> -->
          <!--<a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}} </a>-->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
           <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button" routerLinkActive="active" (click)="itemRemove(item.label)">
                
                <!--  -->
                <div *ngIf="item.label == 'CHAT';else other_content">
                  <span *ngIf="data1 !=0" class="badge chatbadge bg-danger rounded-pill">{{data1}}</span>
                  <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} 
                </div>
              
                <ng-template #other_content>
                  <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} 
                </ng-template>

              <!--  -->
                
                <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
                  <!-- <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">05</span> -->
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-1"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)" href="javascript: void(0);"
                    [routerLink]="subitem.link" routerLinkActive="active">{{subitem.label | translate }}</a>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link"
                          routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">

                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link"
                                routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>

          <!--<ng-container>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/seller/landing']" href="javascript: void(0);" id="topnav-components"
                role="button">
                <i class="bx bx-home-circle me-2"></i>Dashboard<div class="arrow-down"></div>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/seller/contentlist']" href="javascript: void(0);" id="topnav-components"
                role="button">
                <i class="bx bx-tone me-2"></i>Content List<div class="arrow-down"></div>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/seller/contentupload']" href="javascript: void(0);" id="topnav-components"
                role="button">
                <i class="bx bx-customize me-2"></i>Upload Video<div class="arrow-down"></div>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/seller/contentlist']" href="javascript: void(0);" id="topnav-components"
                role="button">
                <i class="bx bx-collection me-2"></i>Content Requests<div class="arrow-down"></div>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/seller/contentlist']" href="javascript: void(0);" id="topnav-components"
                role="button">
                <i class="bx bx-file me-2"></i>FAQ<div class="arrow-down"></div>
              </a>
            </li>
          </ng-container>-->
        </ul>
      </div>
    </nav>
  </div>
</div>
