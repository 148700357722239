import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
var BASE_URL = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class ChatlogsService {

  constructor(private _http:HttpClient) { }
  getChatLogs(userType:string, receiver_id:any):Observable<any>{
    return this._http.get(`${BASE_URL}/api/${userType}/getChatContent?receiver_id=${receiver_id}`)
  }
  // getChatLogs(userType:string, receiver_id:any):Observable<any>{
  //   return this._http.get(`https://vstreamapi.multitvsolution.com/v1/get/attendees/event_id/216`)
  // }
  readNotificationChat(read:any):Observable<any>{
    return this._http.post(`${BASE_URL}/v1/sellers/readmarkChat`, read);
  }
  postOnetoOneChatting(chat):Observable<any>{
    return this._http.post(`https://vstreamapi.multitvsolution.com/v1/one2one/chat/post/event_id/216`, chat);
  }
  enterTochatList(receiver_id, sender_id): Observable<any> {
    return this._http.get(`https://vstreamapi.multitvsolution.com/v1/one2one/chat/get/receiver_id/${receiver_id}/sender_id/${sender_id}`);
  }
}
