import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { CyptolandingComponent } from "./cyptolanding/cyptolanding.component";
import { Page404Component } from "./extrapages/page404/page404.component";
import { LandingComponent } from "./landing/landing.component";
import { VerifymailComponent } from "./account/auth/verifymail/verifymail.component";
import { RegisterComponent } from "./account/auth/register/register.component";
import { ResetPasswordComponent } from "./account/auth/reset-password/reset-password.component";
import { SaleSupportComponent } from "./sale-support/sale-support.component";
import { FaqsComponent } from "./faqs/faqs.component";


const routes: Routes = [
    { path: "account", loadChildren: () => import("./account/account.module").then((m) => m.AccountModule) },
    // tslint:disable-next-line: max-line-length
    { path: "", component: LandingComponent },
    { path: "sale-support", component:SaleSupportComponent },
    {path:"faqs",component:FaqsComponent},
    { path: "verifymail", component: VerifymailComponent },
    { path: "register", component: RegisterComponent },
    { path: "resetpassword", component: ResetPasswordComponent },
    { path: "", component: LayoutComponent, loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule), canActivate: [AuthGuard] },
    { path: "pages", loadChildren: () => import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule), canActivate: [AuthGuard] },
    { path: "crypto-ico-landing", component: CyptolandingComponent },
    { path: "seller", component: LayoutComponent, loadChildren: () => import("./core/seller/seller.module").then((m) => m.SellerModule), canActivate: [AuthGuard] },
    { path: "buyer", component: LayoutComponent, loadChildren: () => import("./core/buyer/buyer.module").then((m) => m.SellerModule), canActivate: [AuthGuard] },
    { path: "**", component: Page404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "top", relativeLinkResolution: "legacy" })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
